import { memo as Memo, useRef, useMemo, useEffect } from 'react'

//* GSAP
import gsap from 'gsap'

//* HOC
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Helper
import { useActiveSection } from 'helpers'

//* Components
import Text from 'components/common/Text'
import Container from 'components/common/Container'
import CocktailCard from 'components/common/CocktailCard'
import Button from 'components/common/Button'

//* Style
import CocktailsSectionStyle from './style'

const CocktailsSection = Memo(({ cocktails, title, button, winWidth }) => {
    //! Refs
    const contRef = useRef()
    const cocktailRefs = useRef([])
    const cocktailImageRefs = useRef([])
    const isAnimated = useRef()

    //! States
    const activeSection = useActiveSection(contRef, 60)

    //! Cocktail Items
    const cocktailItems = useMemo(() => {
        const count = winWidth >= 768 && winWidth < 1024 ? 2 : 3

        return cocktails.map((cocktail, k) => {
            if (k < count) {
                return (
                    <CocktailCard
                        key={k}
                        ref={(ref) => {
                            cocktailRefs.current[k] = ref?.contRef
                            cocktailImageRefs.current[k] = ref?.imageRef
                        }}
                        image={cocktail.featured_image}
                        name={cocktail.name}
                        time={cocktail.time}
                        url={`/cocktails/${cocktail.slug}`}
                    />
                )
            }
        })
    }, [cocktails, winWidth])

    //! Animation
    useEffect(() => {
        const duration = 0.8
        const stagger = 0.1

        gsap.killTweensOf([cocktailRefs.current, cocktailImageRefs.current])
        if (activeSection && !isAnimated.current) {
            isAnimated.current = true
            gsap.to(cocktailRefs.current, { duration, y: '0%', ease: 'power2.out' })
            gsap.to(cocktailImageRefs.current, { delay: 0.3, stagger, duration: 0.8, borderRadius: activeSection ? `${winWidth <= 1024 ? 16 : 24}px` : `60%`, ease: 'power2.out' })
        } else if (!isAnimated.current) {
            gsap.set(cocktailRefs.current, { y: '150%' })
            gsap.set(cocktailImageRefs.current, { borderRadius: `60%` })
        }
    }, [activeSection, winWidth])

    return (
        <Container
            full
            first
        >
            <CocktailsSectionStyle ref={contRef}>
                <Container>
                    <div className='co-top-container'>
                        <Text
                            tag='h2'
                            text={title}
                            className={`minion3-medium uppercase h4 primary-color9 cocktails-section-title`}
                        />

                        {winWidth >= 1024 && (
                            <Button
                                url={`/${button.url}`}
                                text={button.text}
                                className='co-btn'
                            />
                        )}
                    </div>

                    <div className='co-items-container'>{cocktailItems}</div>

                    {winWidth < 1024 && (
                        <div className='co-btn-cont'>
                            <Button
                                url={`/${button.url}`}
                                text={button.text}
                                className='co-btn'
                            />
                        </div>
                    )}
                </Container>
            </CocktailsSectionStyle>
        </Container>
    )
})

export default withUIContext(CocktailsSection, ['winWidth'])
