import styled from 'styled-components'

const AboutUsSectionStyle = styled.div`
    --abItemsSpaceH: calc(var(--sp5x) / 2);
    --abItemsMaxWidth: var(--sp116x);
    --abImagePadding: 108.7%;
    --abImageBorderRadius: var(--sp3x);
    --abTextMarginTop: var(--sp4x);
    --abBtnMarginTop: var(--sp10x);

    display: flex;
    align-items: center;
    justify-content: center;

    .ab-image-container {
        width: 100%;
        max-width: var(--abItemsMaxWidth);
        margin-right: var(--abItemsSpaceH);

        .ab-image {
            --proportion: var(--abImagePadding);
            border-radius: var(--abImageBorderRadius);
            overflow: hidden;
        }
    }

    .ab-info-container {
        width: 100%;
        max-width: var(--abItemsMaxWidth);
        margin-left: var(--abItemsSpaceH);

        .ab-text {
            margin-top: var(--abTextMarginTop);
            white-space: break-spaces;
        }

        .ab-btn {
            margin-top: var(--abBtnMarginTop);
        }
    }

    @media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeL}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXLMin}) {
        --abItemsSpaceH: calc(var(--sp5x) / 2);
        --abItemsMaxWidth: var(--sp87x);
        --abImagePadding: 107.5%;
        --abImageBorderRadius: var(--sp3x);
        --abTextMarginTop: var(--sp3x);
        --abBtnMarginTop: var(--sp6x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeM}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeLMin}) {
        --abItemsSpaceH: calc(var(--sp5x) / 2);
        --abItemsMaxWidth: var(--sp67x);
        --abImagePadding: 108.3%;
        --abImageBorderRadius: var(--sp3x);
        --abTextMarginTop: var(--sp3x);
        --abBtnMarginTop: var(--sp4x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeS}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeMMin}) {
        --abItemsSpaceH: var(--sp2x);
        --abItemsMaxWidth: var(--sp66x);
        --abImagePadding: 108.5%;
        --abImageBorderRadius: var(--sp3x);
        --abTextMarginTop: var(--sp3x);
        --abBtnMarginTop: var(--sp4x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeSMin}) {
        --abItemsSpaceH: var(--sp2x);
        --abItemsMaxWidth: var(--sp59x);
        --abImagePadding: 107.5%;
        --abImageBorderRadius: var(--sp3x);
        --abTextMarginTop: var(--sp3x);
        --abBtnMarginTop: var(--sp4x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
        --abItemsSpaceH: var(--sp2x);
        --abItemsMaxWidth: calc(50% - var(--abItemsSpaceH));
        --abImagePadding: 109%;
        --abImageBorderRadius: var(--sp2x);
        --abTextMarginTop: var(--sp2x);
        --abBtnMarginTop: var(--sp4x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
        --abItemsSpaceH: 0;
        --abItemsMaxWidth: 100%;
        --abImagePadding: 109%;
        --abImageBorderRadius: var(--sp3x);
        --abTextMarginTop: var(--sp2x);
        --abBtnMarginTop: var(--sp4x);

        flex-direction: column-reverse;
        text-align: center;

        .ab-info-container {
            margin-bottom: var(--sp7x);
        }
	}

	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
        --abItemsSpaceH: 0;
        --abItemsMaxWidth: 100%;
        --abImagePadding: 109%;
        --abImageBorderRadius: var(--sp2x);
        --abTextMarginTop: var(--sp2x);
        --abBtnMarginTop: var(--sp4x);

        flex-direction: column-reverse;
        text-align: center;

        .ab-info-container {
            margin-bottom: var(--sp5x);
        }
	}
`

export default AboutUsSectionStyle