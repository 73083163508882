import { memo as Memo, useMemo, useState, useRef, useEffect, useCallback } from 'react';
import gsap from 'gsap';

//* Components
import Container from 'components/common/Container';
import Image from 'components/common/Image';
import Text from 'components/common/Text';

//* Helpers
import { useActiveSection } from 'helpers';

//* Styles
import ProcessesSectionStyle from './style';

const ProcessesSection = Memo(({ processes }) => {
	//! Refs
	const contRef = useRef();
	const tabContRef = useRef();
	const tabRefs = useRef([]);
	const tabLayerRef = useRef();
	const infoRefs = useRef([]);
	const prevActive = useRef();

	//! States
	const [activeTab, setActiveTab] = useState();
	const activeSection = useActiveSection(contRef);

	//! Animate Tab Bg
	useEffect(() => {
		if (activeTab) {
			// const contObj = tabContRef.current.getBoundingClientRect()
			// const contLeft = contObj.left
			// const contRight = contObj.right
			// const item = tabRefs.current[activeTab - 1]
			// const itemRect = item.getBoundingClientRect()
			// const left = itemRect.left - contLeft
			// const right = contRight - itemRect.right
			// const isRight = prevActive.current > activeTab - 1

			// gsap.to(tabLayerRef.current, {
			//     left,
			//     delay: !isRight && 0.2,
			// })
			// gsap.to(tabLayerRef.current, {
			//     right,
			//     delay: isRight && 0.2,
			// })

			gsap.to(infoRefs.current, { stagger: 0.3, duration: 1, opacity: 1 });
		}
	}, [activeTab]);

	//! Update Active Tab
	const updateActiveTab = useCallback((index) => {
		const top = contRef.current.getBoundingClientRect().top;
		window.scrollTo({ top: window.scrollY + top });
		setActiveTab(index);
	}, []);

	//! Did Mount
	useEffect(() => {
		// setActiveTab(1)
		setActiveTab(2);
	}, []);

	//! Tabs
	const tabs = useMemo(() => {
		return (
			<div
				ref={tabContRef}
				className='pr-tabs-container'>
				{processes.map((pr, k) => (
					<div
						key={k}
						ref={(ref) => (tabRefs.current[k] = ref)}
						onClick={() => {
							(prevActive.current = activeTab), updateActiveTab(k + 1);
						}}
						className={`pr-tab-item notoSans-medium p p4 uppercase ${activeTab === k + 1 ? 'active' : ''}`}>
						{pr.title}
					</div>
				))}
				<span
					ref={tabLayerRef}
					className='pr-layer-bg'
				/>
			</div>
		);
	}, [activeTab]);

	//! Infos
	const activeProcesses = useMemo(() => {
		return (
			activeTab && (
				<div className='pr-processes-container'>
					{processes[activeTab - 1].entries.map((pr, k) => (
						<div
							key={`${activeTab}-${k}`}
							ref={(ref) => (infoRefs.current[k] = ref)}
							className='pr-process-container primary-color9'>
							<div className='pr-info-container'>
								<Text
									text={('0' + (k + 1)).slice(-2)}
									className={`pr-number p p3 minion3-regular`}
								/>
								<Text
									tag={'h3'}
									text={pr.title}
									className={`pr-title h6 minion3-medium uppercase`}
								/>
								<Text
									text={pr.text}
									className={`pr-text p p4 notoSans-regular`}
								/>
							</div>

							<div className='pr-image-container'>
								<Image
									src={pr.image.src}
									alt={pr.image.alt}
								/>
							</div>
						</div>
					))}
				</div>
			)
		);
	}, [activeTab]);

	return (
		<ProcessesSectionStyle className={`${activeSection ? 'active-section' : ''}`}>
			<Container
				ref={contRef}
				first
				full>
				<Container className='pr-container'>
					<div className='pr-left-side-cont'>
						<Text
							tag='h2'
							text='ourProcesses'
							className='pr-title h4 minion3-medium uppercase primary-color9'
						/>

						{/* {tabs} */}
					</div>

					<div className='pr-right-side-cont'>{activeProcesses}</div>
				</Container>
			</Container>
		</ProcessesSectionStyle>
	);
});

export default ProcessesSection;
