import { memo as Memo, useEffect, useRef } from 'react';
import gsap from 'gsap';

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer';

//* Components
import Image from 'components/common/Image';
import LottieAnimation from 'components/common/LottieAnimation';

//* Helpers
import { letterSpacingAnimation } from 'helpers';
import scrollArrow from 'lottieFiles/scrollArrow.json';

//* Style
import HeroSectionStyle from './style';

const HeroSection = Memo(({ title, image, isGatesActive }) => {
	//! Refs
	const contRef = useRef();
	const imageContainerRef = useRef();
	const imageRef = useRef();
	const titleRef = useRef();
	const whiteTitleRef = useRef();
	const lottieContRef = useRef();

	//! Animation
	useEffect(() => {
		if (!isGatesActive) {
			gsap.to(imageContainerRef.current, { duration: 1, top: '50%', borderRadius: '24px', ease: 'power3.out' });
			gsap.to(imageContainerRef.current, { duration: 1.8, delay: 0.1, width: '100%', ease: 'power3.out' });
			gsap.to(whiteTitleRef.current, { duration: 1, top: '50%', ease: 'power3.out' });
			letterSpacingAnimation([titleRef.current, whiteTitleRef.current]);
			gsap.to(lottieContRef.current, { delay: 0.5, scale: 1, opacity: 1 });
		}
	}, [isGatesActive]);

	return (
		<HeroSectionStyle ref={contRef}>
			<div className='h-content-container'>
				<h1
					ref={titleRef}
					className='primary-color9 h3 h-title minion3-regular'
					dangerouslySetInnerHTML={{ __html: title }}
				/>

				<div
					ref={imageContainerRef}
					className='h-image-container'>
					<Image
						ref={imageRef}
						className='h-image'
						src={image.src}
						alt={image.alt}
					/>

					<p
						ref={whiteTitleRef}
						className='primary-color8 h3 h-title-white minion3-regular'
						dangerouslySetInnerHTML={{ __html: title }}
					/>
				</div>
			</div>

			<LottieAnimation
				ref={lottieContRef}
				className='arrow-animation'
				animData={scrollArrow}
				autoplay={true}
			/>
		</HeroSectionStyle>
	);
});

export default withUIContext(HeroSection, ['isGatesActive']);
