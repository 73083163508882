import styled from 'styled-components'

const CocktailsSectionStyle = styled.div`
	--coTopContMarginBottom: var(--sp9x);
	--coItemWidth: 33.333%;
	--coItemMargin: var(--sp5x);
	--coItemMarginTop: var(--sp10x);
	--cocktailsSectionTitleWidth: var(--sp189x);

	display: flex;
	flex-direction: column;
	overflow: hidden;

	.co-top-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: var(--coTopContMarginBottom);
	}

	.co-items-container {
		display: flex;
		margin: 0 calc(var(--coItemMargin) / -2);

		.cc-item {
			--ccBorderRadius: 30%;

			width: var(--coItemWidth);
			padding-left: calc(var(--coItemMargin) / 2);
			padding-right: calc(var(--coItemMargin) / 2);

			&:nth-child(3n + 0),
            &:nth-child(3n + 1) {
                margin-top: var(--coItemMarginTop);
            }

			.link-item {
				display: block;
			}
		}
	}

	.cocktails-section-title {
		width: var(--cocktailsSectionTitleWidth);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeL}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXLMin}) {
		--coTopContMarginBottom: var(--sp7x);
		--coItemWidth: 33.333%;
		--coItemMargin: var(--sp5x);
		--coItemMarginTop: var(--sp10x);
		--cocktailsSectionTitleWidth: var(--sp141x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeM}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeLMin}) {
		--coTopContMarginBottom: var(--sp6x);
		--coItemWidth: 33.333%;
		--coItemMargin: var(--sp5x);
		--coItemMarginTop: var(--sp10x);
		--cocktailsSectionTitleWidth: var(--sp110x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeS}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeMMin}) {
		--coTopContMarginBottom: var(--sp6x);
		--coItemWidth: 33.333%;
		--coItemMargin: var(--sp4x);
		--coItemMarginTop: var(--sp8x);
		--cocktailsSectionTitleWidth: var(--sp108x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeSMin}) {
		--coTopContMarginBottom: var(--sp5x);
		--coItemWidth: 33.333%;
		--coItemMargin: var(--sp4x);
		--coItemMarginTop: var(--sp7x);
		--cocktailsSectionTitleWidth: var(--sp96x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
		--coTopContMarginBottom: var(--sp5x);
		--coItemWidth: 33.333%;
		--coItemMargin: var(--sp4x);
		--coItemMarginTop: var(--sp7x);
		--cocktailsSectionTitleWidth: var(--sp76x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
		--coTopContMarginBottom: var(--sp5x);
		--coItemWidth: 50%;
		--coItemMargin: var(--sp3x);
		--coItemMarginTop: var(--sp6x);
		--cocktailsSectionTitleWidth: 100%;

		.co-top-container {
			align-items: center;
			justify-content: center;
		}

		.co-btn-cont {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: var(--sp5x);
		}

		.co-items-container .cc-item {

			&:nth-child(3n + 0),
			&:nth-child(3n + 1) {
				margin-top: 0;
			}

			&:nth-child(2n) {
				margin-top: var(--coItemMarginTop);
			}
		}

		.cocktails-section-title {
			text-align: center;
		}
	}

	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		--coTopContMarginBottom: var(--sp5x);
		--coItemWidth: 100%;
		--coItemMargin: 0;
		--coItemMarginTop: var(--sp4x);
		--cocktailsSectionTitleWidth: 100%;

		.co-top-container {
			align-items: center;
			justify-content: center;
			margin-bottom: 0 !important;
		}

		.co-btn-cont {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: var(--sp4x);
		}

		.co-items-container {
			flex-direction: column;

			.cc-item {
				margin-top: var(--coItemMarginTop) !important;
			}
		}

		.cocktails-section-title {
			text-align: center;
		}
	}
`

export default CocktailsSectionStyle
