import { memo as Memo, useEffect, useRef } from 'react';
import gsap from 'gsap';

//* Components
import Container from 'components/common/Container';
import Image from 'components/common/Image';
import Text from 'components/common/Text';
import Button from 'components/common/Button';

//* Helpers
import { useActiveSection } from 'helpers';

//* Styles
import AboutUsSectionStyle from './style';

const AboutUsSection = Memo(({ image, title, text, button }) => {
	//! Refs
	const contRef = useRef();
	const imageRef = useRef();
	const br = useRef();

	//! States
	const activeSection = useActiveSection(contRef);

	useEffect(() => {
		if (activeSection) {
			gsap.to(imageRef.current, { borderRadius: `${br.current}px`, duration: 1 });
		}
	}, [activeSection]);

	useEffect(() => {
		br.current = gsap.getProperty(imageRef.current, 'borderRadius');
		gsap.set(imageRef.current, { borderRadius: '30%' });
	}, []);

	return (
		<Container first>
			<AboutUsSectionStyle
				ref={contRef}
				className='primary-color9'>
				<div className='ab-image-container'>
					<Image
						ref={imageRef}
						src={image?.src}
						alt={image?.alt}
						className={'ab-image contain'}
					/>
				</div>

				<div className='ab-info-container'>
					<Text
						tag='h2'
						text={title}
						className={`ab-title h4 minion3-medium`}
					/>
					<Text
						text={text}
						className={`ab-text p p3 notoSans-medium`}
					/>

					<Button
						url={button.url}
						text={button.text}
						className='ab-btn'
					/>
				</div>
			</AboutUsSectionStyle>
		</Container>
	);
});

export default AboutUsSection;
