import { forwardRef, useImperativeHandle, useRef } from 'react'

//* HOC
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'

//* Components
import Text from 'components/common/Text'
import Image from 'components/common/Image'
import CustomLink from 'components/common/CustomLink'

//* Style
import CocktailCardStyle from './style'

const CocktailCard = forwardRef(({ className, image, name, time, url, translate }, ref) => {
	//! Refs
	const contRef = useRef()
	const imageRef = useRef()

	useImperativeHandle(ref, () => ({
		contRef: contRef.current,
		imageRef: imageRef.current,
	}))

	return (
		<CocktailCardStyle
			ref={contRef}
			className={`${className || ''} cc-item`}
		>
			<CustomLink url={url}>
				<Image
					ref={imageRef}
					src={image?.src}
					alt={image?.alt}
				/>

				<Text
					tag={`p`}
					className={`p p1 minion3-semiBold primary-color9 uppercase align-center cc-name`}
					text={name}
				/>

				<Text
					tag={`p`}
					className={`p p3 notoSans-regular primary-color9 align-center cc-time`}
				>
					{time}&nbsp;{translate('min')}
				</Text>
			</CustomLink>
		</CocktailCardStyle>
	)
})

export default withLanguageContext(CocktailCard, ['translate'])
