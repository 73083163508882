import styled from 'styled-components'

const ProcessesSectionStyle = styled.div`
    --prContPaddingV: var(--sp22x);
    --prLeftContPos: 15%;
    --prTitleMaxWidth: 99%;
    --prTabContMarginTop: var(--sp7x);
    --prTabItemPadding: var(--sp1-5x) var(--sp4x);
    --prRightSideMaxWidth: 57.6%;
    --prItemPaddingV: var(--sp8x);
    --prItemInfoMarginRight: var(--sp11x);
    --prItemTitleMarginTop: var(--sp1x);
    --prItemTextMarginTop: var(--sp2x);
    --prItemImageWidth: 38.08%;
    
    background: var(--bodyColor);
    transition: background 0.7s;

    &.active-section {
        background: var(--primaryColor1);
    }

    .pr-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: var(--prContPaddingV);
        padding-bottom: var(--prContPaddingV);
        
        .pr-left-side-cont {
            position: sticky;
            top: var(--prLeftContPos);

            .pr-title {
                position: relative;
                max-width: var(--prTitleMaxWidth);
            }

            .pr-tabs-container {
                position: relative;
                display: inline-flex;
                margin-top: var(--prTabContMarginTop);
                border-radius: var(--sp7x);
                background: var(--secondaryColor6);
                overflow: hidden;

                .pr-tab-item {
                    position: relative;
                    padding: var(--prTabItemPadding);
                    color: var(--primaryColor9);
                    transition: color var(--trTime);
                    cursor: pointer;
                    z-index: 1;

                    &.active {
                        color: var(--primaryColor1);
                        pointer-events: none;
                    }
                }

                .pr-layer-bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    background: var(--primaryColor9);
                    border-radius: var(--sp7x);
                    z-index: 0;
                }
            }
        }

        .pr-right-side-cont {
            width: var(--prRightSideMaxWidth);

            .pr-processes-container {
                display: flex;
                flex-direction: column;

                .pr-process-container {
                    display: flex;
                    padding: var(--prItemPaddingV) 0;
                    opacity: 0;

                    &:first-child {
                        padding-top: 0;
                    }

                    &:last-child {
                        padding-bottom: 0;
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid var(--primaryColor9);
                    }

                    .pr-info-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        flex: 1;
                        margin-right: var(--prItemInfoMarginRight);

                        .pr-title {
                            margin-top: var(--prItemTitleMarginTop);
                        }

                        .pr-text {
                            margin-top: var(--prItemTextMarginTop);
                        }
                    }

                    .pr-image-container {
                        width: 100%;
                        max-width: var(--prItemImageWidth);

                        .image-cont {
                            --proportion: 69.72%;

                            border-radius: var(--sp1-5x);
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeL}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXLMin}) {
		--prContPaddingV: var(--sp18x);
        --prLeftContPos: 15%;
        --prTitleMaxWidth: 99%;
        --prTabContMarginTop: var(--sp6x);
        --prTabItemPadding: var(--sp1-5x) var(--sp4x);
        --prRightSideMaxWidth: 57.35%;
        --prItemPaddingV: var(--sp7x);
        --prItemInfoMarginRight: var(--sp8x);
        --prItemTitleMarginTop: var(--sp1x);
        --prItemTextMarginTop: var(--sp2x);
        --prItemImageWidth: 38.08%;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeM}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeLMin}) {
		--prContPaddingV: var(--sp16x);
        --prLeftContPos: 15%;
        --prTitleMaxWidth: 99%;
        --prTabContMarginTop: var(--sp5x);
        --prTabItemPadding: var(--sp1-5x) var(--sp4x);
        --prRightSideMaxWidth: 57.04%;
        --prItemPaddingV: var(--sp5x);
        --prItemInfoMarginRight: var(--sp5x);
        --prItemTitleMarginTop: var(--sp1x);
        --prItemTextMarginTop: var(--sp1-5x);
        --prItemImageWidth: 39.86%;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeS}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeMMin}) {
		--prContPaddingV: var(--sp13x);
        --prLeftContPos: 15%;
        --prTitleMaxWidth: 99%;
        --prTabContMarginTop: var(--sp5x);
        --prTabItemPadding: var(--sp1-5x) var(--sp4x);
        --prRightSideMaxWidth: 57.08%;
        --prItemPaddingV: var(--sp4x);
        --prItemInfoMarginRight: var(--sp4x);
        --prItemTitleMarginTop: var(--sp1x);
        --prItemTextMarginTop: var(--sp1-5x);
        --prItemImageWidth: 39.73%;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeSMin}) {
		--prContPaddingV: var(--sp12x);
        --prLeftContPos: 15%;
        --prTitleMaxWidth: 89%;
        --prTabContMarginTop: var(--sp4x);
        --prTabItemPadding: var(--sp1-5x) var(--sp4x);
        --prRightSideMaxWidth: 57.09%;
        --prItemPaddingV: var(--sp4x);
        --prItemInfoMarginRight: var(--sp4x);
        --prItemTitleMarginTop: var(--sp1x);
        --prItemTextMarginTop: var(--sp1-5x);
        --prItemImageWidth: 40.11%;
	}

    //! Styles under laptop
    @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
        --prLeftContPos: 0;
        --prTitleMaxWidth: 100%;
        --prRightSideMaxWidth: 100%;
        --prLeftSideMarginBottom: var(--sp8x);

        &.active-section .pr-container .pr-left-side-cont:before {
            background: var(--primaryColor1);
            transition:  background 0s 0.4s;
        }

		.pr-container {
            flex-direction: column;
            padding-top: 0;

            .pr-left-side-cont {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                padding-top: var(--prContPaddingV);
                padding-bottom: var(--prLeftSideMarginBottom);
                z-index: 2;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    transition:  none ;
                }
            }
        }
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
		--prContPaddingV: var(--sp11x);
        --prTabContMarginTop: var(--sp4x);
        --prTabItemPadding: var(--sp1x) var(--sp3x);
        --prItemPaddingV: var(--sp5x);
        --prItemInfoMarginRight: var(--sp4x);
        --prItemTitleMarginTop: var(--sp1x);
        --prItemTextMarginTop: var(--sp1-5x);
        --prItemImageWidth: 39.65%;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
		--prContPaddingV: var(--sp11x);
        --prTabContMarginTop: var(--sp3x);
        --prTabItemPadding: var(--sp1x) var(--sp3x);
        --prItemPaddingV: var(--sp5x);
        --prItemInfoMarginRight: var(--sp3x);
        --prItemTitleMarginTop: var(--sp1x);
        --prItemTextMarginTop: var(--sp1-5x);
        --prItemImageWidth: 39.58%;
        --prLeftSideMarginBottom: var(--sp9x);
	}

	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		--prContPaddingV: var(--sp10x);
        --prTabContMarginTop: var(--sp7x);
        --prTabItemPadding: var(--sp1x) var(--sp3x);
        --prItemPaddingV: var(--sp5x);
        --prItemInfoMarginRight: 0;
        --prItemTitleMarginTop: var(--sp1x);
        --prItemTextMarginTop: var(--sp2x);
        --prItemImageWidth: 100%;
        --prLeftSideMarginBottom: var(--sp5x);

        .pr-container .pr-right-side-cont .pr-processes-container .pr-process-container {
            flex-direction: column;

            .pr-image-container {
                margin-top: var(--sp3x);
            }
        }
	}
`

export default ProcessesSectionStyle