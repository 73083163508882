import styled from 'styled-components'

const HeroSectionStyle = styled.div`
	--hContentContPaddingTop: 38.549%;
	--hImageWidth: 35.7%;
	--hArrowWidth: var(--sp6x);
	--hArrowMarginTop: var(--sp13x);

	padding-left: var(--contPaddingLR);
	padding-right: var(--contPaddingLR);
	overflow: hidden;

	.h-content-container {
		position: relative;
		padding-top: var(--hContentContPaddingTop);
		z-index: 2;

		.h-title,
		.h-title-white {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			white-space: nowrap;
			letter-spacing: 1.8rem;
			opacity: 0;
		}

		.h-title-white {
			top: -100%;
		}

		.h-image-container {
			position: absolute;
			top: 200%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: var(--hImageWidth);
			height: 100%;
			margin: 0 auto;
			border-radius: 50%;
			overflow: hidden;

			.h-image {
				--proportion: unset;

				position: absolute;
				top: 50%;
				left: 50%;
				width: calc(100vw - (2 * var(--contPaddingLR)));
				height: 100%;
				transform: translate(-50%, -50%);

				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: rgb(0 0 0 / 32%);
				}
			}
		}
	}

	.arrow-animation.arrow-animation {
		width: var(--hArrowWidth);
		margin: var(--hArrowMarginTop) auto 0;
		transform-origin: top center;
		opacity: 0;
		transform: scale(0);

		svg {
			max-width: 100%;
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeL}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXLMin}) {
		--hContentContPaddingTop: 39.45%;
		--hImageWidth: 36.5%;
		--hArrowWidth: var(--sp5x);
		--hArrowMarginTop: var(--sp11x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeM}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeLMin}) {
		--hContentContPaddingTop: 40.55%;
		--hImageWidth: 37.5%;
		--hArrowWidth: var(--sp5x);
		--hArrowMarginTop: var(--sp11x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeS}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeMMin}) {
		--hContentContPaddingTop: 39.65%;
		--hImageWidth: 36.6%;
		--hArrowWidth: var(--sp4x);
		--hArrowMarginTop: var(--sp8x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeSMin}) {
		--hContentContPaddingTop: 40.15%;
		--hImageWidth: 37%;
		--hArrowWidth: var(--sp4x);
		--hArrowMarginTop: var(--sp10x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
		--hContentContPaddingTop: 41.25%;
		--hImageWidth: 38%;
		--hArrowWidth: var(--sp4x);
		--hArrowMarginTop: var(--sp18x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
		--hContentContPaddingTop: 108.55%;
		--hImageWidth: 65.5%;
		--hArrowWidth: var(--sp4x);
		--hArrowMarginTop: var(--sp13x);
	}

	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		--hContentContPaddingTop: 200%;
		--hImageWidth: 65.5%;
		--hArrowWidth: var(--sp4x);
		--hArrowMarginTop: var(--sp3x);
	}
`

export default HeroSectionStyle
