import styled from 'styled-components'

const CocktailCardStyle = styled.div`
	--ccBorderRadius: var(--sp3x);
	--ccNameMTop: var(--sp4x);
	--ccTimeMTop: var(--sp1-5x);
	--ccImageHeight: 135%;

	height: 100%;

	.image-cont {
		--proportion: var(--ccImageHeight);
        
		overflow: hidden;
		isolation: isolate;
		border-radius: var(--ccBorderRadius);

		img {
			transform: scale(1);
			transition: transform var(--trTime) ease-out;
		}
	}

	.cc-name {
		margin-top: var(--ccNameMTop);
	}

	.cc-time {
		margin-top: var(--ccTimeMTop);
	}

	.link-item {
		@media (hover: hover) {
			&:hover {
				.image-cont {
					img {
						transform: scale(1.3);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--ccBorderRadius: var(--sp3x);
		--ccNameMTop: var(--sp3x);
		--ccTimeMTop: var(--sp1x);
		--ccImageHeight: 135%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--ccBorderRadius: var(--sp3x);
		--ccNameMTop: var(--sp2x);
		--ccTimeMTop: var(--sp1x);
		--ccImageHeight: 135.5%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--ccBorderRadius: var(--sp3x);
		--ccNameMTop: var(--sp2x);
		--ccTimeMTop: var(--sp1x);
		--ccImageHeight: 134.7%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--ccBorderRadius: var(--sp3x);
		--ccNameMTop: var(--sp2x);
		--ccTimeMTop: var(--sp1x);
		--ccImageHeight: 134.8%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--ccBorderRadius: var(--sp2x);
		--ccNameMTop: var(--sp2x);
		--ccTimeMTop: var(--sp1x);
		--ccImageHeight: 134.8%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--ccBorderRadius: var(--sp2x);
		--ccNameMTop: var(--sp2x);
		--ccTimeMTop: var(--sp1x);
		--ccImageHeight: 134.6%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--ccBorderRadius: var(--sp2x);
		--ccNameMTop: var(--sp2x);
		--ccTimeMTop: var(--sp1x);
		--ccImageHeight: 133.7%;
	}
`

export default CocktailCardStyle
